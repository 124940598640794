<template>
  <div class="app">
    <div class="background">
      <div class="output">
        <ul>
          <li class="output_li" v-for="(item,index) in list" :key="index">
            <span v-if="index%2==0" v-html="item" style="font-size: 17px;font-weight: 600;"></span>
            <span v-else style="font-weight: 400;font-size: 20px;">{{ item }}</span>
            <span v-if="item==''" style="font-size: 15px;font-weight: 350;">等待数据返回中...</span>

          </li>
        </ul>
        <!-- <div class="ouput_item" v-for="(item,index) in list" :key="index" ><p v-html="item"></p></div>   -->
      </div>
      <div>

      </div>
      <div class="input">
        <input class="input_input" type="text" v-model="userInput">
        <button class="input_button" @click="Send()"><span>Search</span></button>
      </div>

      
    </div>

  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'ChatGPT',
  props: {
    
  },
  data(){
    return{
      list:[],
      index:0,
      websocket:null,
      userInput:"",
      sid:""
    }
  },
  created(){
    this.sid = prompt("你现在要为你自己指定一个不少于四位的唯一ID~")

      if(this.sid==null||this.sid.length<4){
          var random =Math.floor(Math.random()*(9999-1000+1))+1000
          alert("由于你做了一个不合理的操作，现已为你分配了一个随机id:"+random);
          this.sid=random
      }
      
    
    console.log(this.sid)
    this.setupWebSocket()
    
    
  },

  methods:{
    setupWebSocket() {
      this.websocket = new WebSocket("ws://47.99.147.155:8080/ws/"+this.sid); // 创建WebSocket连接
      this.websocket.onopen = this.onWebSocketOpen; // WebSocket连接打开时的处理函数
      this.websocket.onmessage = this.onWebSocketMessage; // 收到WebSocket消息时的处理函数
      this.websocket.onclose = this.onWebSocketClose; // WebSocket连接关闭时的处理函数
    },
    onWebSocketOpen() {
      console.log("链接成功");
    },
    onWebSocketMessage(event){
      const content=event.data
      console.log("Receive:"+content)
      this.list[this.index]+=content
      console.log(this.list)
    },
    onWebSocketClose(){

    },
    Send(){
      this.list.push(this.userInput)
      this.index++

      this.list.push("")
      var _this =this
      axios.get("http://47.99.147.155:8080/chatHistoryStream?message="+this.userInput+"&sid="+this.sid).then(
        _this.index++
      )
      this.userInput=""
    }

  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped> 
  .output_li{
    margin: 10px;
  }
  .input_button span{
    font-weight: bold;
    font-size: 15px;
  }
  .input_button{
    position: absolute;
    right: 20px;
    height: 50px;
    width: 120px;
    border-radius: 15px;
    border: 2px solid rgba(220, 220, 220, 0.877);
    cursor: pointer
  }

  .input_button:active{
    border-color: black;
  }
  .input_input{
    position: absolute;
    left: 20px;
    height: 50px;
    border-radius: 15px;
    width: 1000px;
    border: 1px solid gainsboro;
    padding: 0px;
    padding-left: 15px;
    font-size: 16px;
  }

  .ouput_item{
    margin-bottom: 10px;
    background-color: aquamarine;
  }
  .output{
    text-align: left;
    margin: 20px;
  }
  .output ul{
    height: 600px;
    overflow-y: scroll;
    /* background-color: aqua; */

  }
  .input{
    position: absolute;
    bottom: 30px;
    left: 200px;
    right: 200px;
    font-size: 50px;
    height: 50px;
    /* background-color: antiquewhite; */

  }
  .background{
    position: relative;
    background-color: rgb(237, 242, 248);
    width: 1600px;
    border-radius: 25px;
    border: 1px solid rgb(171, 219, 219);
    padding-bottom: 90px;
  }
  .app{
    display: flex;
    justify-content: center
  }
</style>
